import React, { Component } from "react";
import Slider from "react-slick";
import "./index.css";

class AsNavFor extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  render() {
    const topSliderSettings = {
      className: "center",
      centerMode: true,
      infinite: true,
      arrows: false,
      slidesToShow: 7,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
          },
        },
      ],
    };

    const bottomSliderSettings = {
      className: "bottom-slider",
      infinite: true,
      arrows: false,
      slidesToShow: 1,
      swipeToSlide: true,
      focusOnSelect: true,
      dots: true,
    };

    return (
      <div>
        <Slider
          asNavFor={this.state.nav2}
          ref={(slider) => (this.slider1 = slider)}
          {...topSliderSettings}
        >
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-2.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-2.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-3.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-4.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-5.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-6.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-7.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-8.png")}
            />
          </div>
          <div>
            <img
              className="img-fluid testimonial-img"
              src={require("../../images/testimonial/test-9.png")}
            />
          </div>
        </Slider>
        <Slider
          asNavFor={this.state.nav1}
          ref={(slider) => (this.slider2 = slider)}
          {...bottomSliderSettings}
        >
          <div className="text-white text-center">
            <p className="testomi-feedback">
              "Taxi kwam ons ophalen van de AirPort met al onze koffers.
              Meegeholpen met dragen van de koffers   en ze zelf in de auto
              gezet. Hele vriendelijke en behulpzame taxichauffeur."
            </p>
            <h2 className="testimoni-author">Johnnie Olson</h2>
          </div>
          <div className="text-white text-center">
            <p className="testomi-feedback">
              "Taxi gereserveerd bij Taxionspot. Op tijd opgehaald en afgezet.
              Fijne rit gehad met een aardige, sociale   taxichauffeur!"
            </p>
            <h2 className="testimoni-author">Mareno Lemmens</h2>
          </div>
          <div className="text-white text-center">
            <p className="testomi-feedback">
              "Ritje naar van Eindhoven AirPort naar Eindhoven station
              gereserveerd via de site Taxionspot. Nette   auto en op tijd,
              prima ritje!"
            </p>
            <h2 className="testimoni-author">Joep Hartmans</h2>
          </div>
          <div className="text-white text-center">
            <p className="testomi-feedback">
              "Taxi besteld online. Meteen een bevestiging gekregen. Was bang
              dat de taxi niet zou komen, maar de   chauffeur kwam goed op
              tijd."
            </p>
            <h2 className="testimoni-author">Lieke Janssen</h2>
          </div>
          <div className="text-white text-center">
            <p className="testomi-feedback">
              "Goed initiatief van Taxionspot, eerste bedrijf die ik ken waar je
              zo makkelijk een taxi kan bestellen. Je   krijgt gelijk een
              bevestiging wie je chauffeur is en in wat voor auto je weggereden
              wordt"
            </p>
            <h2 className="testimoni-author">Eline SchoenmakerS</h2>
          </div>
        </Slider>
      </div>
    );
  }
}

export default () => null;
